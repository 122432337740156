<template>
    <div>
        <agora-react-web-uikit
        ref="agora_video"
        :style="styleProp"
        :appId="appId"
        :channel="channel"
        :token="token"
        enableAudio="true"
    />
        <div v-if="closeVideo">
            <h3   class=" d-flex justify-center mx-auto my-14 "> Thank you !! </h3>
            <h3  class=" d-flex justify-center mx-auto my-0 "> You will now be redirected </h3>

        </div>
      

        
    </div>    
    
</template>

<script>
import axios from 'axios'

export default {
    props: ['appId','channel','token','styleProp','meetinglinkid','uid'],
    async mounted(){
      // select web component
      const el = this.$el.querySelector('agora-react-web-uikit');
      
      // Add event listener and subscribe to custom event
      el.addEventListener('agoraUIKitEndcall', (e) => {
        let resp = confirm("Are you sure you want to exit ?");
        if(resp == true){
            el.remove();
            this.closeVideo = true
        }
        console.log(e)
        // handle endcall event
        

      });
    },
   async destroyed(){
    try{
        await axios.get('https://pub-expapi.entym.app/meetingend', {
        test : this.meetinglinkid,
        test2: this.uid
      })
    }
    catch(e){
        console.error(e)
    }
    
   },
    data(){
        return {
            closeVideo : false,
            displayUsername : true
        }
    },
    watch : {
        closeVideo(newVal){
            if(newVal === true){
                setTimeout( ()=> {
                console.log('after timeout')
                //location.reload(true);
                //this.$router.go(0);
                window.location.href = "https://www.entym.com/"
            }, 5000 );
                
            }
        }
    }

}
</script>

<style>

</style>