<template>

  <v-container fluid>
       
    <div v-if="showJoinButton" class=" my-14">

      <!-- <v-row justify="center">
        <v-col  align="center" cols="6" >
          <v-text-field
            label="Enter your display name"
            solo
            v-model="displayName"
            maxlength="20"
          ></v-text-field>

        </v-col>       
      </v-row> -->

      <v-row>
        <input type="text" :value="name">
      </v-row>

      <v-row>
        <v-col align="center">
           <v-btn  @click="handleJoinClick()" >
            Click to join the meeting!!
            </v-btn>
        </v-col>
       
      </v-row>
        
        
      
    </div>

    

    <v-row v-if="join"  >
      <v-col>
          <video-meet :appId="appId" :channel="channel" :token="token" :styleProp="styleProp" :meetinglinkid="$route.query.vf_link_id" :uid="$route.query.cod" > </video-meet>
        
      </v-col>
      

    </v-row>
    
    <div v-if="invalidLink">
       <v-card>
         <h3> Invalid Link </h3>
       </v-card>
    </div>
     
    

  </v-container>
</template>

<script>
import axios from 'axios'
import VideoMeet from '../components/VideoMeet.vue'
// @ is an alias to /src
export default {
  name: 'HomeView',
  components: {
    VideoMeet
  },
  data(){
    return {
      showJoinButton: true,
      join: false,
      appId: '',
      channel: '',
      token : '',
      displayName: '',
      invalidLink: false,
      styleProp:'width: 100%; height: 100vh; display:flex;',
      name : ''
    }
  },
  methods: {
    async handleJoinClick() {
      this.showJoinButton = false
      
      //console.log(this.displayName)
       //console.log(this.$route.query.vf_link_id)
      // Call api and assign values
        
        try{
        const resp = await axios.get('https://pub-expapi.entym.app/getmeetingtoken',{ params: {test:this.$route.query.vf_link_id, test2: this.$route.query.cod , signature : this.$route.query.signature }})
        console.log(resp.data)
        if(resp.data == "ERROR") throw 'Server returned with an error'

        let data = resp.data.token.split("---&&&&&---")
        //let checker = arr => arr.every(v => v === true);
        //const isRespValid = checker(data)
        //if(!isRespValid) throw 'Invalid Parameters Error'
         this.appId = data[0]
         this.channel = data[1]
         this.token = data[2]
         this.join = !this.join
        }
        catch(e){
          console.error(e)
          this.invalidLink = true
        }


      
    },

  },
  mounted(){
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    if(vh <= 600){
      this.styleProp = 'width: 100%; height: 80vh; display:flex;'
    }
    if (vh >= 600 && vh <960){
      this.styleProp = 'width: 100%; height: 90vh; display:flex;'
    }
    if(vh >= 960){
      this.styleProp = 'width: 100%; height: 95vh; display:flex;'
    } 
    console.log(vh)
  }

}
</script>

<style scoped>
.grid{
  display: grid;
  place-items: center;
}
.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}
.videContainer{
  max-height: 90vh;
  overflow-y: auto;
}
</style>
