<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      
      dense>
    <v-app-bar-title > <v-btn ><v-img contain max-height="35" max-width="40"  :src=logoImage></v-img>   </v-btn>  </v-app-bar-title>

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
    logoImage : require('@/assets/entym_low.png')
  }),
};
</script>
